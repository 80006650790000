<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="600px">
    <div class="maintain">
      <div class="maintain_head">{{ details.type }}</div>
      <div class="maintain_txt" v-html="details.content"></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      details:{},
    }
  },
  methods:{
    open () {
      
      this.$axios.fangLookGuiZe().then(res=>{
        this.details = res.data
        this.dialogVisible = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.maintain_head {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
}
.maintain_txt {
  color: #666666;
  font-size: 16px;
  line-height: 30px;
}
</style>